@import '../../styles/variables';
@import '../../styles/mixin';

.figure {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: $gray-50;
  border-radius: 8px;

  img {
    height: auto;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.cta_button,
.caption__cta_button {
  transition: $transition-all;
  border-radius: $border-radius-btn;
  white-space: nowrap;
  font-size: $text-sm;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;

  .button__content {
    width: 100%;
  }

  &_primary {
    min-width: 184px;
    padding: 11px 30px;
    color: $label-btn-primary;
    background: $btn-primary;
    border: 1px solid $border-btn-primary;

    @include media(sm) {
      min-width: 284px;
    }

    &:not(:disabled):hover {
      color: $label-btn-primary-hover;
      background: $btn-primary-hover;
      border: 1px solid $border-btn-primary-hover;
    }

    &:not(:disabled):focus {
      color: $label-btn-primary-pressed;
      background: $btn-primary-pressed;
      border: 1px solid $border-btn-primary-pressed;
    }

    &:disabled {
      color: $label-btn-primary-disabled;
      background: $btn-primary-disabled;
      border: 1px solid $border-btn-primary-disabled;
    }
  }
}

.image_gallery {
  background-color: $background-theme;

  .image_gallery_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;

    h3 {
      font-size: $text-base !important;
      color: $gray-300;
      line-height: 24px;
      font-weight: 400 !important;
      letter-spacing: -0.02px;
    }

    h1,
    h2 {
      font-size: 2rem !important;
      line-height: 40px !important;
      text-align: center;
      color: $gray-600;

      @include media(sm) {
        font-size: $text-5xl !important;
        line-height: 58px !important;
      }
    }

    p {
      color: #606e89;
      text-align: center;
      font-size: $text-base !important;
      width: 80vw;

      @include media(sm) {
        max-width: 814px;
      }

      &.carousel_description {
        max-width: 445px !important;
        font-size: $text-base !important;
        line-height: 20px;
      }
    }

    .gallery__images {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      //max-width: 1320px;
      display: flex;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 1.5rem;

      &_custom_width {
        width: 100%;
      }

      &_tabs {
        margin-top: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        row-gap: 6px;

        @include media(md) {
          padding-left: 62px;
        }

        @include media(lg) {
          padding-left: 33px;
        }

        @include media(xl) {
          padding-left: 0;
        }

        @include media(2xl) {
          padding-left: 132px;
        }

        @include media(3xl) {
          padding-left: 237px;
        }

        &_centered {
          justify-content: center;
          padding-left: 0;
        }

        &_container {
          padding: 0 !important;
        }

        &_tab {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          column-gap: 5px;
          font-weight: 700;
          letter-spacing: -0.02px;
          padding: 10px 16px;
          line-height: 28px;
          border-bottom: 3px solid #dadfeb;
          transition: all 0.2s ease;
          cursor: pointer;

          @include media(sm) {
            font-size: $text-base;
            padding: 10px 30.5px;
          }

          span {
            font-size: $text-base;
            font-weight: 400;
            display: none;

            @include media(sm) {
              display: inline;
            }
          }

          &_active {
            transition: all 0.5s ease;
            border-bottom: 3px solid #3972e5;
          }

          &:hover {
            transition: all 0.5s ease;
            border-bottom: 3px solid #3972e5;
          }
        }
      }

      &_hidden {
        display: none;

        @include media(md) {
          display: flex;
        }
      }

      &_carousel {
        display: flex;
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;

        @include media(md) {
          display: none;
        }

        &_slide {
          width: 264px;
        }

        [class*='swiper-button-prev'],
        [class*='swiper-button-next'] {
          display: none;
        }
      }

      &_empty_results {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        justify-content: center;
        height: 400px;
        text-align: center;

        i {
          font-size: $text-3xl;
          font-weight: 600;
        }

        @include media(lg) {
          height: 500px;
        }
      }

      &_item_body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 290px;
        row-gap: 12px;

        @include media(3xl) {
          width: 365.5px;
        }

        &_custom_width {
          width: 100%;
          height: auto;
        }

        &_square {
          width: 368px;

          @media screen and (max-width: 428px) {
            width: 320px;
          }

          @include media(md) {
            width: 320px;
          }

          @include media(lg) {
            width: 395px;
          }

          @include media(3xl) {
            width: 495px;
          }
        }

        &_horizontal_image {
          width: 290px;

          @include media(3xl) {
            width: 395px;
          }
        }

        @include media(sm) {
          row-gap: 24px;
        }

        &:hover {
          .caption__container {
            opacity: 1;
          }
        }

        .caption__container {
          order: 2;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: $white;
          border: 1px solid #a1acc2;
          border-radius: $border-radius-md;
          opacity: 0;
          transition: all 0.6s ease;
          z-index: 2;
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          align-items: center;
          justify-content: center;
          padding: 40px;

          h5 {
            font-size: $text-xs;
            line-height: 20px;
            letter-spacing: -0.02px;
            color: #a1acc2;
            font-weight: 500;
          }

          h4 {
            font-size: $text-xl;
            line-height: 28px;
            letter-spacing: -0.02px;
            color: #39455c;
            font-weight: 700;

            @include media(sm) {
              font-size: $text-2xl;
              line-height: 32px;
            }
          }

          p {
            margin-top: 6px;
            color: #606e89;
            text-align: center;
            font-size: $text-base;
            letter-spacing: -0.02px;
            max-width: 315px;
            line-height: 24px;
          }

          &_tabs {
            opacity: 1;
            background-color: transparent;
            justify-content: flex-end;
            border: 0;
            padding: 20px;

            &_gradient_effect {
              background: linear-gradient(
                to bottom,
                rgba(252, 253, 255, 0) 0%,
                rgba(0, 0, 0, 0.65) 85%
              );
            }

            h5 {
              font-size: $text-sm;
              line-height: 20px;
            }

            h4 {
              font-size: $text-xl;
              line-height: 28px;
            }
          }

          &_disable {
            position: relative;
            border-radius: 0;
            background-color: transparent;
            border: 0;
            padding: 0 20px;
            opacity: 1;
            order: 2;

            @include media(sm) {
              padding: 0 24px;
            }

            h5 {
              font-size: 10px;
              line-height: 18px;
            }

            h4 {
              font-size: $text-xl;
              line-height: 28px;
            }

            p {
              font-size: $text-sm !important;
              line-height: 20px;
            }
          }

          .caption__cta_button {
            margin-top: 29px;

            &_primary {
              height: 36px;
              padding: 6px 30px;
              min-width: 160px;
            }

            &_link {
              color: $label-btn-link;
              font-size: $text-base;
              font-weight: 400;
              padding: 0 6px;
              text-decoration: underline;

              &:not(:disabled):hover,
              &:not(:disabled):focus {
                color: $label-btn-link-hove;
              }

              @include media(sm) {
                font-weight: 700;
              }
            }
          }
        }
      }

      &_item {
        @extend .figure;
        position: relative;
        width: 100%;
        padding-top: 135.43%;
        order: 1;

        &_square {
          padding-top: 100%;
        }

        &_horizontal_image {
          padding-top: 48.28%;
        }

        &_custom_width {
          aspect-ratio: 16/9;
          width: 100%;
          height: auto;
          padding-top: 0;
        }

        img {
          border-radius: $border-radius-md;
        }
      }
    }

    .gallery_grid {
      margin: 32px auto;
      display: grid;
      gap: 16px;

      @include media(xl) {
        gap: 24px;
      }

      $one-row: 190px;
      $one-row-xs: 220px;
      $one-row-sm: 288px;
      $one-row-md: 320px;
      $one-row-lg: 396px;
      $one-row-xl: 503px;
      $one-row-2xl: 604px;
      $one-row-3xl: 755px;
      $one-row-r: 302px;
      $one-row-2r: 365px;
      $two-rows: repeat(2, 190px);
      $two-rows-xs: repeat(2, 220px);
      $two-rows-sm: repeat(2, 288px);
      $two-rows-md: repeat(2, 320px);
      $two-rows-xl: repeat(2, 365px);
      $three-rows: repeat(3, 220px);
      $three-rows-sm: repeat(3, 288px);
      $three-rows-xl: repeat(3, 290px);
      $three-rows-2xl: repeat(3, 365px);
      $four-rows: repeat(4, 140px);
      $four-rows-xs: repeat(4, 190px);
      $four-rows-sm: repeat(4, 288px);
      $six-rows: repeat(6, 140px);
      $six-rows-xs: repeat(6, 190px);
      $six-rows-sm: repeat(6, 288px);
      $one-column: 288px;
      $one-column-lg: 396px;
      $one-column-xl: 503px;
      $one-column-2xl: 604px;
      $one-column-3xl: 755px;
      $two-columns: repeat(2, 140px);
      $two-columns-xs: repeat(2, 190px);
      $two-columns-sm: repeat(2, 288px);
      $two-columns-md: repeat(2, 320px);
      $two-columns-lg: repeat(2, 456px);
      $two-columns-2xl: repeat(2, 604px);
      $two-columns-3xl: repeat(2, 755px);
      $three-columns: repeat(3, 190px);
      $three-columns-md: repeat(3, 318px);
      $three-columns-lg: repeat(3, 396px);
      $three-columns-xl: repeat(3, 503px);
      $four-columns: repeat(4, 220px);
      $four-columns-sm: repeat(4, 288px);
      $four-columns-md: repeat(4, 302px);
      $four-columns-lg: repeat(4, 365px);
      $four-columns-xl: repeat(4, 290px);

      $images: (
        '1': 'A',
        '2': 'B',
        '3': 'C',
        '4': 'D',
        '5': 'E',
        '6': 'F',
      );

      &.grid_1 {
        grid-template-rows: $one-row-sm;
        grid-template-columns: $one-column;
        grid-template-areas: 'A';

        @media screen and (min-width: 428px) {
          grid-template-rows: $one-row-lg;
          grid-template-columns: $one-column-lg;
        }

        @include media(xl) {
          grid-template-rows: $one-row-xl;
          grid-template-columns: $one-column-xl;
        }

        @include media(2xl) {
          grid-template-rows: $one-row-2xl;
          grid-template-columns: $one-column-2xl;
        }

        @include media(3xl) {
          grid-template-rows: $one-row-3xl;
          grid-template-columns: $one-column-3xl;
        }
      }

      &.grid_2 {
        grid-template-rows: $two-rows-sm;
        grid-template-columns: $one-column;
        grid-template-areas:
          'A'
          'B';

        @media screen and (min-width: 428px) {
          grid-template-rows: $one-row;
          grid-template-columns: $two-columns-xs;
          grid-template-areas: 'A B';
        }

        @include media(sm) {
          grid-template-rows: $one-row-sm;
          grid-template-columns: $two-columns-sm;
        }

        @include media(md) {
          grid-template-rows: $one-row-md;
          grid-template-columns: $two-columns-md;
        }

        @include media(lg) {
          grid-template-rows: $one-row-lg;
          grid-template-columns: $two-columns-lg;
        }

        @include media(xl) {
          grid-template-rows: $one-row-2xl;
          grid-template-columns: $two-columns-2xl;
        }

        @include media(3xl) {
          grid-template-rows: $one-row-3xl;
          grid-template-columns: $two-columns-3xl;
        }
      }

      &.grid_3 {
        grid-template-rows: $three-rows-sm;
        grid-template-columns: $one-column;
        grid-template-areas:
          'A'
          'B'
          'C';

        @media screen and (min-width: 428px) {
          grid-template-rows: $three-columns-lg;
          grid-template-columns: $one-column-lg;
        }

        @include media(sm) {
          grid-template-rows: $one-row;
          grid-template-columns: $three-columns;
          grid-template-areas: 'A B C';
        }

        @include media(lg) {
          grid-template-rows: $one-row-md;
          grid-template-columns: $three-columns-md;
        }

        @include media(xl) {
          grid-template-rows: $one-row-lg;
          grid-template-columns: $three-columns-lg;
        }

        @include media(3xl) {
          grid-template-rows: $one-row-xl;
          grid-template-columns: $three-columns-xl;
        }
      }

      &.grid_4 {
        grid-template-rows: $four-rows-sm;
        grid-template-columns: $one-column;
        grid-template-areas:
          'A'
          'B'
          'C'
          'D';

        @media screen and (min-width: 428px) {
          grid-template-rows: $two-rows;
          grid-template-columns: $two-columns-xs;
          grid-template-areas:
            'A B'
            'C D';
        }

        @include media(sm) {
          grid-template-rows: $two-rows-sm;
          grid-template-columns: $two-columns-sm;
        }

        @include media(md) {
          grid-template-rows: $two-rows-md;
          grid-template-columns: $two-columns-md;
        }

        @include media(lg) {
          grid-template-rows: $one-row-xs;
          grid-template-columns: $four-columns;
          grid-template-areas: 'A B C D';
        }

        @include media(xl) {
          grid-template-rows: $one-row-r;
          grid-template-columns: $four-columns-md;
        }

        @include media(2xl) {
          grid-template-rows: $one-row-sm;
          grid-template-columns: $four-columns-sm;
        }

        @include media(3xl) {
          grid-template-rows: $one-row-2r;
          grid-template-columns: $four-columns-lg;
        }
      }

      &.grid_5 {
        grid-template-rows: $four-rows;
        grid-template-columns: $two-columns;
        grid-template-areas:
          'A B'
          'C D'
          'E E'
          'E E';

        @media screen and (min-width: 428px) {
          grid-template-rows: $four-rows-xs;
          grid-template-columns: $two-columns-xs;
        }

        @include media(sm) {
          grid-template-rows: $four-rows-sm;
          grid-template-columns: $two-columns-sm;
        }

        @include media(lg) {
          grid-template-rows: $two-rows-xs;
          grid-template-columns: $four-columns;
          grid-template-areas:
            'A B E E'
            'C D E E';
        }

        @include media(xl) {
          grid-template-rows: $two-rows-sm;
          grid-template-columns: $four-columns-sm;
          grid-template-areas:
            'A B E E'
            'C D E E';
        }

        @include media(3xl) {
          grid-template-rows: $two-rows-xl;
          grid-template-columns: $four-columns-lg;
        }
      }

      &.grid_6 {
        grid-template-rows: $six-rows;
        grid-template-columns: $two-columns;
        grid-template-areas:
          'A B'
          'C C'
          'C C'
          'D D'
          'D D'
          'E F';

        @media screen and (min-width: 428px) {
          grid-template-rows: $six-rows-xs;
          grid-template-columns: $two-columns-xs;
        }

        @include media(sm) {
          grid-template-rows: $six-rows-sm;
          grid-template-columns: $two-columns-sm;
        }

        @include media(lg) {
          grid-template-rows: $three-rows;
          grid-template-columns: $four-columns;
          grid-template-areas:
            'A B D D'
            'C C D D'
            'C C E F';
        }

        @include media(xl) {
          grid-template-rows: $three-rows-xl;
          grid-template-columns: $four-columns-xl;
          grid-template-areas:
            'A B D D'
            'C C D D'
            'C C E F';
        }

        @include media(3xl) {
          grid-template-rows: $three-rows-2xl;
          grid-template-columns: $four-columns-lg;
        }
      }

      @each $num, $letter in $images {
        .grid_item__#{$num} {
          position: relative;
          grid-area: unquote($letter);
          @extend .figure;

          img {
            border-radius: $border-radius-md;
          }
        }
      }
    }

    .gallery_carousel {
      margin: 0 auto;
      display: flex;
      margin-top: 12px;
      max-width: 1240px;
      width: 100%;

      @include media(sm) {
        margin-top: 32px;
      }

      .gallery__item_slice {
        width: 264px;

        @include media(sm) {
          width: 600px;
        }

        @include media(lg) {
          width: 79.84%;
        }
      }

      .gallery_carousel__slide {
        @extend .figure;
        position: relative;
        width: 100%;
        padding-top: 49.19%;
        overflow: hidden;

        img {
          height: auto;
          border-radius: $border-radius-md;
        }
      }

      [class*='swiper-button-prev'],
      [class*='swiper-button-next'] {
        display: none;

        @include media(sm) {
          display: flex;
          width: 48px !important;
          height: 48px !important;
          border-radius: 50%;
          padding: 0.25rem;
          color: #070c15;
          background: rgba(255, 255, 255, 0.45);
        }

        &::after {
          @include media(sm) {
            font-size: 0.625rem !important;
            font-weight: bold;
          }
        }
      }
    }

    .gallery_cta {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }

  .carousel_container {
    > p {
      order: 4;

      @include media(lg) {
        margin-top: 8px;
      }
    }

    .gallery_cta {
      order: 5;
    }
  }

  .container__effect_img {
    figure {
      img,
      svg {
        scale: 1;
        transition: 0.8s all ease 0.4s;

        &:hover,
        &:focus-within {
          transition: 0.8s all ease 0s;
          scale: 1.03;
        }
      }
    }
  }
}
